import React from "react";
import PropTypes from "prop-types";

const Layout = ({ children }) => (
  <div className="mx-auto max-w-screen-xl xl:shadow-3d">
    {children}
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
