module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Perform On Top","short_name":"Perform On Top","description":"Perform On Top is a social project in Sweden that unites people who work on their personal and professional development.","start_url":"/","lang":"en","background_color":"#ffffff","theme_color":"#ed8936","display":"standalone","icon":"src/images/meta/pot-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f11895c088b2c2f9c0e834c3ee8a52f9"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
