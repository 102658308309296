import React from "react";
import PropTypes from "prop-types";

const CopyrightNotice = ({ handleClose }) => {
  return (
    <div className="fixed inset-0 min-h-screen p-4 mx-auto bg-gray-900/50 sm:p-6 md:p-8 lg:p-10 max-w-screen-xl">
      <div className="flex flex-col h-full overflow-auto">
        <div className="flex items-center justify-between p-2 bg-gray-300 rounded-t-lg">
          <p className="text-2xl">Copyright notice</p>
          <button
            onClick={handleClose}
            className="w-8 h-8 text-xl text-center bg-white rounded-full"
          >
            &#10005;
          </button>
        </div>
        <div className="p-2 bg-white select-auto">
          <p className="mb-4">
            This website and its content is copyright of Perform On Top ©. All
            rights reserved.
          </p>
          <p>
            Any redistribution or reproduction of part or all of the contents in
            any form is prohibited other than the following:
          </p>
          <ul className="m-4 list-disc">
            <li>
              you may print or download to a local hard disk extracts for your
              personal and non-commercial use only
            </li>
            <li>
              you may copy the content to individual third parties for their
              personal use, but only if you acknowledge the website as the
              source of the material
            </li>
          </ul>
          <p>
            You may not, except with our expressed written permission,
            distribute or commercially exploit the content. Nor may you transmit
            it or store it in any other website or other form of electronic
            retrieval system.
          </p>
        </div>
      </div>
    </div>
  );
};

CopyrightNotice.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default CopyrightNotice;
