import React, { useState } from "react";
//import PropTypes from "prop-types";
import {
  FaFacebookSquare,
  FaFacebookMessenger,
  FaLinkedin,
  FaEnvelope,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "gatsby";
import PrivacyPolicy from "./PrivacyPolicy";
import CopyrightNotice from "./CopyrightNotice";
import CookieConsent from "./CookieConsent";

const MailchimpSignup = () => {
  return (
    <div className="mt-4 lg:text-lg">
      <form
        action="https://performontop.us2.list-manage.com/subscribe/post?u=74b52f82d945c4c6af892bc03&amp;id=d7a88dcad0"
        method="post"
        name="mc-embedded-subscribe-form"
        target="_blank"
        className="flex flex-col items-center"
      >
        <div
          style={{ position: "absolute", left: "-5000px" }}
          aria-hidden="true"
        >
          <input
            type="text"
            name="b_74b52f82d945c4c6af892bc03_d7a88dcad0"
            tabIndex="-1"
          />
        </div>

        <label htmlFor="mce-EMAIL" className="block font-bold text-center">
          Subscribe to newsletter
        </label>
        <div className="flex flex-wrap items-center justify-center">
          <input
            id="mce-EMAIL"
            type="email"
            placeholder="Email address"
            name="EMAIL"
            className="px-4 py-2 mt-2 border border-gray-500 rounded outline-none focus:border-orange-700"
            required
          />

          <input
            type="submit"
            value="Subscribe"
            name="subscribe"
            className="mx-2 bg-orange-500 cursor-pointer hover:bg-orange-700 submit-btn"
          />
        </div>
      </form>
    </div>
  );
};

const FooterSection = () => {
  const [showPrivacy, toggleShowPrivacy] = useState(false);
  const [showCopyright, toggleShowCopyright] = useState(false);
  const handleShowPrivacy = () => toggleShowPrivacy(!showPrivacy);
  const handleShowCopyright = () => toggleShowCopyright(!showCopyright);
  return (
    <footer className="w-full p-6 bg-gray-300 mob:px-8 md:px-10 lg:px-12">
      <div className="flex flex-col items-center justify-between">
        <div className="flex flex-col items-center justify-between w-full mob:flex-row mob:items-start">
          <div className="text-center mob:text-left">
            <p className="lg:text-lg">Contact us</p>
            <div className="flex items-center mt-1 lg:mt-2">
              <FaEnvelope className="block mr-2 text-2xl lg:text-4xl" />
              <p>contact@performontop.com</p>
            </div>
          </div>
          <div className="mt-2 text-center mob:mt-0 mob:text-left">
            <p className="lg:text-lg">Join us</p>
            <div className="mt-1 lg:mt-2">
              <a
                href="https://www.facebook.com/PerformOnTop/"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block pr-1 text-2xl lg:text-4xl"
              >
                <FaFacebookSquare />
              </a>
              <a
                href="https://www.facebook.com/groups/173099860774220/"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block pr-1 text-2xl lg:text-4xl"
              >
                <FaFacebookMessenger />
              </a>
              <a
                href="https://www.linkedin.com/company/perform-on-top/"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block pr-1 text-2xl lg:text-4xl"
              >
                <FaLinkedin />
              </a>
              <a
                href="https://www.youtube.com/channel/UCvMNeUD6oSPWjrxp_aUKpgw/"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block text-2xl lg:text-4xl"
              >
                <FaYoutube />
              </a>
            </div>
          </div>
        </div>
        <MailchimpSignup />
        <div className="flex flex-col items-center justify-around w-full mt-4 mob:flex-row mob:items-start">
          <Link
            to="/about-us/"
            role="menuitem"
            className="text-orange-700 underline hover:text-blue-700"
          >
            About us
          </Link>
          <button
            onClick={handleShowPrivacy}
            className="mt-1 text-orange-700 underline mob:mt-0 hover:text-blue-700"
          >
            GDPR &amp; privacy policy
          </button>
          <button
            onClick={handleShowCopyright}
            className="mt-1 text-orange-700 underline mob:mt-0 hover:text-blue-700"
          >
            Copyright notice
          </button>
        </div>
        <p className="mt-4 text-center">
          &copy; Perform On Top. All rights reserved.
        </p>
      </div>
      {showPrivacy ? <PrivacyPolicy handleClose={handleShowPrivacy} /> : null}
      {showCopyright ? (
        <CopyrightNotice handleClose={handleShowCopyright} />
      ) : null}
      <CookieConsent doShowPrivacy={handleShowPrivacy} />
    </footer>
  );
};

FooterSection.propTypes = {};

export default FooterSection;
