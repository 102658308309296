import React from "react";
import Layout from "./src/components/Layout";
import FooterSection from "./src/components/FooterSection";

const wrapPageElement = ({ element }) => (
  <Layout>
    {element}
    <FooterSection />
  </Layout>
);

export default wrapPageElement;
