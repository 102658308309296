import React from "react";
import PropTypes from "prop-types";

const policyData = [
  {
    title: "Overview",
    content: [
      "Perform On Top © is strongly committed to protecting the privacy of its website users. We have written this privacy policy to outline the specific information we collect, how we use this information, and what options you have in sharing this information with us when you visit our site. Protecting your privacy is a significant part of our commitment to maintaining the highest standards in all that we do.",
      "This privacy policy will explain how our organization uses the personal data we collect from you when you use our website and the data we collect from you during our events.",
    ],
  },
  {
    title: "What data we collect",
    content: [
      "We collect the following data:",
      [
        "Personal identification information (name, email address, phone number, personnummer)",
        "Any other data you submit to us on your own will for coaching purposes (such as sample of your CV or motivation letter)",
      ],
    ],
  },
  {
    title: "How we collect your data",
    content: [
      "We collect and process data when you:",
      [
        "Register online to any of our events",
        "Use or view our website via your browser’s cookies",
        "Sign up for our newsletter",
        "Upload your CV on our website or send it via email",
      ],
    ],
  },
  {
    title: "How we use your data",
    content: [
      "We collect your data to be able to:",
      [
        "Contact you when you send us a submission for individual help och event",
        "Send a report about our events to our donors",
        "With your additional approval we might send your CV or motivation letter to a potential employer (our partner)",
      ],
    ],
  },
  {
    title: "How we store your data",
    content: [
      "Perform On Top securely stores your data at internal storage space with restricted access. Perform On Top will keep your personal information you entered in a submission via the website for 1 year. Once this time period has expired, we will delete your data.",
    ],
  },
  {
    title: "Marketing",
    content: [
      "Perform On Top would like to send you information about the upcoming events via a newsletter powered by MailChimp in case you sign up and agree to receive it. You have the right at any time to stop Perform On Top from contacting you for marketing purposes.",
    ],
  },
  {
    title: "Your data protection rights",
    content: [
      "Every user is entitled to the following:",
      [
        "The right to access – you have the right to request Perform On Top for copies of your personal data.",
        "The right to rectification – you have the right to request that Perform On Top correct any information you believe is inaccurate. You also have the right to request Perform On Top to complete the information you believe is incomplete.",
        "The right to erasure – you have the right to request that Perform On Top erase your personal data, under certain conditions.",
        "The right to restrict processing – you have the right to request that Perform On Top restrict the processing of your personal data, under certain conditions.",
        "The right to object to processing – you have the right to object to Perform On Top’s processing of your personal data, under certain conditions.",
        "The right to data portability – you have the right to request that Perform On Top transfer the data that we have collected to another organization, or directly to you, under certain conditions.",
      ],
    ],
  },
  {
    title: "Website security",
    content: [
      "Our website has security installed to ensure that any personal data entered onto the site is protected against loss, misuse or alteration. However, due to the nature of the internet, we cannot guarantee the protection of your personal information. We are confident about our security measures and it is always a top priority to ensure we do not incur any problems.",
    ],
  },
  {
    title: "Use of cookies",
    content: [
      "We employ cookie technology to store and sometimes track information about the users of the site. You can set your browser not to accept cookies though some of our website features may not work as a result.",
    ],
  },
  {
    title: "What are cookies?",
    content: [
      "Cookies are a feature of web browser software that allows web servers to recognize the computer used to access a web site. Cookies are small pieces of data that are stored by a user's web browser on the user's hard drive. Cookies can remember what information a user accesses on one web page to simplify subsequent interactions with that website by the same user or to use the information to streamline the user's transactions on related web pages. This makes it easier for a user to move from web page to web page and to complete commercial transactions over the Internet. Cookies should make your online experience easier and more personalized.",
    ],
  },
  {
    title: "Links",
    content: [
      "The site may contain links to third-party websites and third-party websites may contain links to our site. Please be aware that we are not responsible for the privacy practices or the content of these other sites.",
      "We encourage our users to be aware when they leave our site and to read the privacy policies or statements of third-party sites before proceeding. These other sites may send their own cookies to users, collect data, or solicit personal information. This Privacy Policy applies solely to information collected at our site.",
    ],
  },
  {
    title: "Google analytics",
    content: [
      "Perform On Top © uses Google Analytics, a web analytics service provided by Google, Inc. Google Analytics sets a cookie in order to evaluate your use of our web sites and compiles reports for us on activity on our web sites.",
      "Google stores the information collected by the cookie on servers in the United States. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google’s behalf. Google will not associate your IP address with any other data held by Google. By using the Perform On Top © website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.",
    ],
  },
  {
    title: "Acceptance of privacy policy",
    content: [
      "By using the site, you signify your assent to our Privacy Policy. If you do not agree with this Privacy Policy, please do not use our site. Your continued use of this site following posting of changes to these terms will mean you accept those changes.",
    ],
  },
  {
    title: "Changes to privacy policy",
    content: [
      "Perform On Top keeps its privacy & cookies policy under regular review and places any updates on this web page. This privacy policy was last updated on 2020-10-10.",
    ],
  },
  {
    title: "Contact us",
    content: [
      "If you have any questions about Perform On Top’s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.",
      "If you would like to exercise any of these rights, please contact us at our email: contact@performontop.com.",
      "You may also call us at 076 320 99 91 or 076 011 50 17.",
    ],
  },
];

const ParagraphBlock = ({ content, className, key }) => (
  <p className={className} key={key}>
    {content}
  </p>
);

ParagraphBlock.propTypes = {
  content: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
};

const ListBlock = ({ content, className, title }) => {
  const items = content.map((listItem, idx) => (
    <li className={idx === 0 ? null : "mt-2"} key={`${title}-list-${idx}`}>
      {listItem}
    </li>
  ));
  return <ul className={`${className} pl-2 list-disc list-inside`}>{items}</ul>;
};

ListBlock.propTypes = {
  content: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const PolicySection = ({ title, content, isLast }) => {
  const extraClass = isLast ? "rounded-b-lg" : "mb-8";
  const contentBlocks = content.map((contentItem, idx, arr) =>
    Array.isArray(contentItem) ? (
      <ListBlock
        content={contentItem}
        className={idx === arr.length - 1 ? "mb-0" : "mb-4"}
        title={title}
      />
    ) : (
      <ParagraphBlock
        content={contentItem}
        className={idx === arr.length - 1 ? "mb-0" : "mb-4"}
        key={`${title}-block-${idx}`}
      />
    )
  );
  return (
    <div className={extraClass}>
      <p className="mb-4 text-xl">{title}</p>
      {contentBlocks}
    </div>
  );
};

PolicySection.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  isLast: PropTypes.bool.isRequired,
};

const PrivacyPolicy = ({ handleClose }) => {
  const policyParts = policyData.map((data, idx, arr) => (
    <PolicySection
      {...data}
      isLast={idx === arr.length - 1}
      key={`policy-section-${idx}`}
    />
  ));
  return (
    <div className="fixed inset-0 min-h-screen p-4 mx-auto bg-gray-900/50 lg:p-10 max-w-screen-xl">
      <div className="flex flex-col h-full overflow-auto">
        <div className="flex items-center justify-between p-2 bg-gray-300 rounded-t-lg">
          <p className="text-2xl">GDPR &amp; privacy policy</p>
          <button
            onClick={handleClose}
            className="w-8 h-8 text-xl text-center bg-white rounded-full"
          >
            &#10005;
          </button>
        </div>
        <div className="p-2 bg-white select-auto">{policyParts}</div>
      </div>
    </div>
  );
};

PrivacyPolicy.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default PrivacyPolicy;
