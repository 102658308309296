import React from "react";
import PropTypes from "prop-types";
import CookieConsent from "react-cookie-consent";

const containerStyle = {
  background: "#dd6b20",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const contentStyle = {
  fontSize: "1rem",
  textAlign: "left",
  maxWidth: "85%",
};

const agreeButtonStyle = {
  backgroundColor: "rgba(220, 220, 220, 0.75)",
  color: "black",
  fontSize: "1rem",
  borderRadius: "0.5rem",
};

const PoTCookieConsent = ({ doShowPrivacy }) => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="I Agree"
      cookieName="PerformOnTopCookie"
      style={containerStyle}
      contentStyle={contentStyle}
      buttonStyle={agreeButtonStyle}
    >
      We use cookies for measuring website visit statistics. To learn more about
      cookies, view our{" "}
      <button className="underline" onClick={doShowPrivacy}>
        Privacy Policy
      </button>
      . By clicking &quot;I Agree&quot; or using our site, you consent to the
      use of cookies, unless you have disabled them.
    </CookieConsent>
  );
};

PoTCookieConsent.propTypes = {
  doShowPrivacy: PropTypes.func.isRequired,
};

export default PoTCookieConsent;
