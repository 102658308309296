exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-green-growth-and-culture-index-js": () => import("./../../../src/pages/green-growth-and-culture/index.js" /* webpackChunkName: "component---src-pages-green-growth-and-culture-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-joinus-js": () => import("./../../../src/pages/joinus.js" /* webpackChunkName: "component---src-pages-joinus-js" */),
  "component---src-pages-self-improvement-index-js": () => import("./../../../src/pages/self-improvement/index.js" /* webpackChunkName: "component---src-pages-self-improvement-index-js" */),
  "component---src-pages-shine-bright-index-js": () => import("./../../../src/pages/shine-bright/index.js" /* webpackChunkName: "component---src-pages-shine-bright-index-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-transition-to-it-index-js": () => import("./../../../src/pages/transition-to-it/index.js" /* webpackChunkName: "component---src-pages-transition-to-it-index-js" */),
  "component---src-pages-welcome-to-sweden-index-js": () => import("./../../../src/pages/welcome-to-sweden/index.js" /* webpackChunkName: "component---src-pages-welcome-to-sweden-index-js" */)
}

